import { collection, getDocs, query } from 'firebase/firestore'
import { db } from '../../../firebase_config/firebase_config'
import { InviteToBrandNotification } from '../../../types'

export const getTeamNotifications = async (teamId: string) => {
    const q = query(collection(db, `teams/${teamId}/notifications`))
    try {
        const notifications = await getDocs(q)
        return notifications.docs.map((doc) => ({ ...doc.data(), id: doc.id })) as InviteToBrandNotification[]
    } catch (error: any) {
        throw new Error(error)
    }
}
