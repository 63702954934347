import { useEffect, useState } from 'react'
import { useTeamContext } from '../contexts/TeamContext'
import { getTeamNotifications } from '../api/firestore/notifications'
import { Notification } from '../types'

interface IReturn {
    notifications: Notification[]
    setRefetchIsNeeded: () => void
}

export const useTeamNotifications = (): IReturn => {
    const { currentTeam } = useTeamContext()
    const [notifications, setNotifications] = useState<Notification[]>([])
    const [refetchIsNeeded, setRefetchIsNeeded] = useState<boolean>(false)

    useEffect(() => {
        const fetchNotifications = async () => {
            const res = await getTeamNotifications(currentTeam.id)
            setNotifications(res)
        }

        if (currentTeam?.id || refetchIsNeeded) {
            fetchNotifications()

            setRefetchIsNeeded(false)
        }
    }, [currentTeam, refetchIsNeeded])

    return {
        notifications,
        setRefetchIsNeeded: setRefetchIsNeeded as () => void,
    }
}

export default useTeamNotifications
